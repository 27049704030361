import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import AdminLayout from "../components/AdminLayout";

interface Movement {
  data: string;
  lojaId: string;
  userId: string;
  valorRecebidoCashback: string;
  valorTotalCompra: string;
}

interface Coupon {
  id: string;
  titulo: string;
  desconto: number;
  dataInicio: string;
  dataValidade: string;
}

const StoreDashboard: React.FC = () => {
  const { nomeEstabelecimento } = useParams<{ nomeEstabelecimento: string }>();
  const [movements, setMovements] = useState<Movement[]>([]);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [cashbackTotal, setCashbackTotal] = useState(0);
  const [purchaseTotal, setPurchaseTotal] = useState(0);

  useEffect(() => {
    if (nomeEstabelecimento) {
      fetchMovements();
      fetchCoupons();
    }
  }, [nomeEstabelecimento]);

  const fetchMovements = async () => {
    try {
      const q = query(
        collection(db, "movimentacoes"),
        where("lojaId", "==", nomeEstabelecimento)
      );
      const snapshot = await getDocs(q);

      const fetchedMovements: Movement[] = snapshot.docs.map((doc) => ({
        ...doc.data(),
      })) as Movement[];

      setMovements(fetchedMovements);

      // Calcula o total de cashback e compras
      const totalCashback = fetchedMovements.reduce(
        (sum, movement) => sum + parseFloat(movement.valorRecebidoCashback),
        0
      );
      const totalPurchases = fetchedMovements.reduce(
        (sum, movement) => sum + parseFloat(movement.valorTotalCompra),
        0
      );

      setCashbackTotal(totalCashback);
      setPurchaseTotal(totalPurchases);
    } catch (error) {
      console.error("Erro ao buscar movimentações:", error);
    }
  };

  const fetchCoupons = async () => {
    try {
      const q = query(
        collection(db, "cupons"),
        where("nomeLoja", "==", nomeEstabelecimento)
      );
      const snapshot = await getDocs(q);

      const fetchedCoupons: Coupon[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        titulo: doc.data().titulo,
        desconto: doc.data().desconto,
        dataInicio: doc.data().dataInicio,
        dataValidade: doc.data().dataValidade,
      }));

      setCoupons(fetchedCoupons);
    } catch (error) {
      console.error("Erro ao buscar cupons:", error);
    }
  };

  return (
    <AdminLayout>
      <main className="admin-main-content">
        <header className="admin-header">
          <h1>Dashboard - {nomeEstabelecimento}</h1>
        </header>

        <section className="admin-stats-cards">
          <div className="admin-card">
            <h3>Total de Cashback Gerado</h3>
            <p>R$ {cashbackTotal.toFixed(2)}</p>
          </div>
          <div className="admin-card">
            <h3>Valor Total de Compras</h3>
            <p>R$ {purchaseTotal.toFixed(2)}</p>
          </div>
          <div className="admin-card">
            <h3>Quantidade de Movimentações</h3>
            <p>{movements.length}</p>
          </div>
        </section>

        <section className="admin-section">
          <h2>Movimentações</h2>
          <table className="admin-table">
            <thead>
              <tr>
                <th>Data</th>
                <th>Valor Total da Compra</th>
                <th>Valor Cashback</th>
              </tr>
            </thead>
            <tbody>
              {movements.map((movement, index) => (
                <tr key={index}>
                  <td>{movement.data}</td>
                  <td>R$ {parseFloat(movement.valorTotalCompra).toFixed(2)}</td>
                  <td>R$ {parseFloat(movement.valorRecebidoCashback).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="admin-section">
          <h2>Cupons</h2>
          <table className="admin-table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Desconto (%)</th>
                <th>Data Início</th>
                <th>Data Validade</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon) => (
                <tr key={coupon.id}>
                  <td>{coupon.titulo}</td>
                  <td>{coupon.desconto}</td>
                  <td>{coupon.dataInicio}</td>
                  <td>{coupon.dataValidade}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </AdminLayout>
  );
};

export default StoreDashboard;
