import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth"; // Adiciona persistência
import { auth, db } from "../firebaseConfig"; // Autenticação Firebase
import { collection, query, where, getDocs } from "firebase/firestore"; // Para buscar no Firebase Firestore
import "../css/Auth.css"; // Certifique-se de importar o CSS

const Login: React.FC = () => {
  const [cnpj, setCnpj] = useState("");
  const [senha, setSenha] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Checar se o usuário já está autenticado e redirecionar para a página de boas-vindas
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/boasvindas"); // Usuário autenticado, redireciona
      }
    });

    return () => unsubscribe(); // Limpa o listener quando o componente é desmontado
  }, [navigate]);

  const formatCnpj = (value: string) => {
    const onlyNumbers = value.replace(/\D/g, ""); // Remove tudo que não é número
    if (onlyNumbers.length <= 14) {
      const part1 = onlyNumbers.slice(0, 2);
      const part2 = onlyNumbers.slice(2, 5);
      const part3 = onlyNumbers.slice(5, 8);
      const part4 = onlyNumbers.slice(8, 12);
      const part5 = onlyNumbers.slice(12, 14);

      if (onlyNumbers.length > 12) {
        return `${part1}.${part2}.${part3}/${part4}-${part5}`;
      } else if (onlyNumbers.length > 8) {
        return `${part1}.${part2}.${part3}/${part4}`;
      } else if (onlyNumbers.length > 5) {
        return `${part1}.${part2}.${part3}`;
      } else if (onlyNumbers.length > 2) {
        return `${part1}.${part2}`;
      } else {
        return part1;
      }
    }
    return value.slice(0, 18); // Limita o comprimento máximo
  };

  const removeFormatting = (value: string) => value.replace(/\D/g, ""); // Remove formatação

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const usersRef = collection(db, "users");
      let email = cnpj; // Email ou CNPJ tratado como email
      let querySnapshot;

      if (isAdmin) {
        // Login de admin
        const q = query(usersRef, where("email", "==", email));
        querySnapshot = await getDocs(q);
      } else {
        // Login de loja (CNPJ)
        const cnpjWithoutFormatting = removeFormatting(cnpj);
        const q = query(usersRef, where("cnpj", "==", cnpjWithoutFormatting));
        querySnapshot = await getDocs(q);
      }

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        email = userDoc.data().email;

        await setPersistence(auth, browserLocalPersistence);

        // Faz login com email e senha
        await signInWithEmailAndPassword(auth, email, senha);

        const userData = userDoc.data();
        if (userData.isAdmin) {
          navigate("/admin"); // Redireciona para admin se for admin
        } else {
          navigate("/boasvindas"); // Redireciona para boas-vindas
        }
      } else {
        setError("CNPJ ou Email não encontrado");
      }
    } catch (err) {
      setError("Falha no login. Verifique suas credenciais.");
      console.error(err);
    }
  };

  const handleRegisterRedirect = () => {
    navigate("/cadastro");
  };

  return (
    <div className="container-all">
      <div className="container">
        <div className="welcome-section">
          <img
            src={require("../assets/logo.png")}
            alt="Pixinxa Logo"
            className="logo"
          />
          <h1>Bem vindo(a)</h1>
          <p>
            Aumente suas vendas com nossa solução de cashback e fidelize
            clientes. Junte-se à Pixinxa hoje para oferecer recompensas que
            fazem com que os clientes voltem sempre!
          </p>
        </div>
        <div className="auth-section">
          <h1>Login de Loja</h1>
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="auth-check"> 
              <input
              className="checkbox"
                type="checkbox"
                checked={isAdmin}
                onChange={() => setIsAdmin((prev) => !prev)}
              />
              <p>É admin?</p>
            </div>
            {isAdmin ? (
              <input
                type="email"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                placeholder="Email"
                required
              />
            ) : (
              <input
                type="text"
                value={cnpj}
                onChange={(e) => setCnpj(formatCnpj(e.target.value))}
                placeholder="CNPJ"
                required
              />
            )}
            <input
              type="password"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Senha"
              required
            />
            <button type="submit">Entrar</button>
          </form>
          <p>Ainda não tem uma conta?</p>
          <button className="secondary-button" onClick={handleRegisterRedirect}>
            Cadastre sua loja aqui
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;