import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../firebaseConfig";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../../css/CreateStore.css";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/CustomDropdown/CusotmDropdown";
import AdminLayout from "../../components/AdminLayout";

const CreateStore: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [storeData, setStoreData] = useState<{
    nomeCompleto: string;
    email: string;
    cnpj: string;
    telefone: string;
    categoria: string;
    subcategoria: string;
    logoUrl: string | File;
  }>({
    nomeCompleto: "",
    email: "",
    cnpj: "",
    telefone: "",
    categoria: "",
    subcategoria: "",
    logoUrl: "",
  });

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("");
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [cropperInstance, setCropperInstance] = useState<any>(null);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      const fetchedCategories = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        imageUrl: doc.data().imageUrl,
        icon: doc.data().icon,
        subcategories: doc.data().subcategories || [],
      }));
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogoFile(e.target.files[0]);
      setShowCropperModal(true); // Abre o modal de corte
    }
  };

  const handleCrop = () => {
    if (cropperInstance) {
      const croppedCanvas = cropperInstance.getCroppedCanvas();
      setCroppedImage(croppedCanvas.toDataURL());
      croppedCanvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const croppedFile = new File([blob], "cropped-logo.png", {
            type: "image/png",
          });
          setStoreData({ ...storeData, logoUrl: croppedFile });
        }
      });
      setShowCropperModal(false); // Fecha o modal
    }
  };

  function formatCNPJ(value: string): string {
    // Remove caracteres não numéricos
    const cnpj = value.replace(/\D/g, "");

    // Formata no padrão XX.XXX.XXX/XXXX-XX
    return cnpj
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .slice(0, 18); // Limita a 18 caracteres (formato completo)
  }

  function cleanCNPJ(value: string): string {
    // Remove caracteres não numéricos
    return value.replace(/\D/g, "");
  }

  function isCNPJValid(cnpj: string): boolean {
    const cleanCNPJValue = cnpj.replace(/\D/g, "");

    if (cleanCNPJValue.length !== 14) return false;

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cleanCNPJValue)) return false;

    const calcDigit = (base: number): number => {
      let sum = 0;
      let pos = base - 7;
      for (let i = base; i >= 1; i--) {
        sum += parseInt(cleanCNPJValue[base - i]) * pos--;
        if (pos < 2) pos = 9;
      }
      const result = sum % 11;
      return result < 2 ? 0 : 11 - result;
    };

    const digit1 = calcDigit(12);
    const digit2 = calcDigit(13);

    return (
      digit1 === parseInt(cleanCNPJValue[12]) &&
      digit2 === parseInt(cleanCNPJValue[13])
    );
  }

  const criarLoja = async () => {
    const { nomeCompleto, email, cnpj, telefone, categoria, subcategoria } =
      storeData;

      const cleanCnpj = cleanCNPJ(cnpj);

      if (!isCNPJValid(cleanCnpj)) {
        setNotification({
          message: "CNPJ inválido! Verifique e tente novamente.",
          type: "error",
        });
        return;
      }

    try {
      let logoUrl = "";
      if (storeData.logoUrl && storeData.logoUrl instanceof File) {
        const logoRef = ref(storage, `logos/${cleanCnpj}`);
        await uploadBytes(logoRef, storeData.logoUrl);
        logoUrl = await getDownloadURL(logoRef);
      }

      await addDoc(collection(db, "users"), {
        nomeCompleto,
        email,
        cleanCnpj,
        telefone,
        categoria,
        subcategoria,
        logoUrl,
        isCnpj: true,
        senha: "123456",
        lojasCaixinhas: [],
      });

      setNotification({ message: "Loja criada com sucesso!", type: "success" });
      navigate("/admin");
    } catch (error) {
      setNotification({ message: "Erro ao criar loja!", type: "error" });
      console.error("Erro ao criar loja:", error);
    }
  };

  return (
    <AdminLayout>
      <div className="create-store-container">
        <h1 className="create-store-title">Criar Nova Loja</h1>
        <div className="create-store-form">
          <div className="logo-upload-container">
            <input
              ref={fileInputRef}
              type="file"
              name="logoUrl"
              accept="image/*"
              onChange={handleLogoUpload}
              className="logo-input"
            />
            <div
              className="logo-preview-container"
              onClick={() => fileInputRef.current?.click()}
            >
              {croppedImage ? (
                <img
                  src={croppedImage}
                  alt="Pré-visualização da Logo"
                  className="logo-preview"
                />
              ) : (
                <span className="logo-placeholder">Escolher foto</span>
              )}
            </div>
          </div>
          <div className="create-store-row">
            <div className="create-store-column">
              <label>Nome Completo:</label>
              <input
                type="text"
                name="nomeCompleto"
                value={storeData.nomeCompleto}
                onChange={(e) =>
                  setStoreData({ ...storeData, nomeCompleto: e.target.value })
                }
                required
              />
            </div>
            <div className="create-store-column">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={storeData.email}
                onChange={(e) =>
                  setStoreData({ ...storeData, email: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="create-store-row">
            <div className="create-store-column">
              <label>CNPJ:</label>
              <input
                type="text"
                name="cnpj"
                value={storeData.cnpj}

                  // Validação em tempo real

  
                onChange={(e) => {
                  const formattedCNPJ = formatCNPJ(e.target.value);
                  setStoreData({ ...storeData, cnpj: formattedCNPJ });
                  if (!isCNPJValid(formattedCNPJ)) {
                    setNotification({
                      message: "CNPJ inválido!",
                      type: "error",
                    });
                  } else {
                    setNotification(null);
                  }
                }}

                
                placeholder="XX.XXX.XXX/XXXX-XX"
                maxLength={18}
                required
              />
            </div>
            <div className="create-store-column">
              <label>Telefone:</label>
              <input
                type="text"
                name="telefone"
                value={storeData.telefone}
                onChange={(e) =>
                  setStoreData({ ...storeData, telefone: e.target.value })
                }
                placeholder="+XX (XX) XXXXX-XXXX"
                maxLength={18}
                required
              />
            </div>
          </div>

          <div className="create-store-row">
            <div className="create-store-column">
              <label>Categoria:</label>
              <CustomDropdown
                categories={categories}
                selectedCategory={selectedCategory}
                onSelect={setSelectedCategory}
              />
            </div>
            <div className="create-store-column">
              <label>Subcategoria:</label>
              <CustomDropdown
                categories={
                  selectedCategory
                    ? categories.find(
                        (category) => category.name === selectedCategory
                      )?.subcategories || []
                    : []
                }
                selectedCategory={selectedSubcategory}
                onSelect={setSelectedSubcategory}
                disabled={!selectedCategory}
              />
            </div>
          </div>

          {showCropperModal && (
            <div className="cropper-modals">
              <div className="cropper-modal-content">
                <h3 className="cropper-title">Ajustar Corte</h3>
                <Cropper
                  src={logoFile ? URL.createObjectURL(logoFile) : ""}
                  style={{ height: 300, width: "100%" }}
                  aspectRatio={1}
                  viewMode={1}
                  guides={true}
                  dragMode="move"
                  onInitialized={(instance) => setCropperInstance(instance)}
                />
                <div className="cropper-modal-buttons">
                  <button onClick={() => setShowCropperModal(false)}>
                    Cancelar
                  </button>
                  <button onClick={handleCrop}>Confirmar</button>
                </div>
              </div>
            </div>
          )}

          <button
            type="button"
            className="create-store-submit-button"
            onClick={criarLoja}
          >
            Criar Loja
          </button>
        </div>
        {notification && (
          <div
            className={`create-store-notification ${
              notification.type === "success"
                ? "create-store-notification-success"
                : "create-store-notification-error"
            }`}
          >
            {notification.message}
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default CreateStore;
